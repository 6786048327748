import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,      
  display: 'flex',
  flexDirection: 'column',      
  alignItems: 'center', 
};

/*export const contentStyle: SxProps = {
  width: { xs: '40rem', md:'100rem' },
  height: {
    flexGrow: 1,     
  },
  minHeight: '40rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: '4rem',
  marginTop: '2rem', 
};*/

export const contentStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '45rem',
    md: '55rem',
    lg: '85rem',      
  },
  height: {
    flexGrow: 1,      
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',    
  alignItems: 'center',   
  marginTop: {
    xs: '5rem',
    lg: '6rem',
  },
  marginBottom: {
    xs: '5rem',
    lg: '6rem',
  },
};

export const titleBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
};

export const thumbnailBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '16.87rem',
    sm: '25.31rem',
    md: '30.93rem',
    lg: '47.81rem',
    //xl: '56.25rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',    
};

export const h1Style: SxProps = {
  width: '96%',
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
    lg: '2.2rem'
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
  textAlign: 'center',
};

export const h1MainStyle: SxProps = {
  width: '100%',
  fontSize: {
    xs: '1.8rem',
    sm: '2.0rem',
    lg: '3rem'
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textAlign: 'center',
  marginBottom: {
    xs: '3rem',
    sm: '3.5rem',
    lg: '5rem',
  },
};

export const imgStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

export const infoBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '3rem',
  },    
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',      
  alignItems: 'center',
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',  
};

export const descriptionStyle: SxProps = {
  fontSize: {
    xs: '1.4rem',
    sm: '1.8rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginTop: '2rem',
  whiteSpace: 'break-spaces',
  textAlign: 'justify',    
};

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    md: '42rem',
    lg: '86rem',
  },
  height: {
    flexGrow: 1,
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  alignItems: 'center',
  justifyContent: {
    xs: 'center',
    lg: 'space-between',
  },
  marginTop: '4rem',
  marginBottom: '4rem',     
};

export const buttonStyle: SxProps = {
  width: {
    xs: '22rem',
    lg: '20rem',
  },
  height: {
    xs: '4rem',
    lg: '5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '0.4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '1.5rem',
    lg: '1.7rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginBottom: {
    xs: '1rem',
  },
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-hover)' },   
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const modalStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { 
    xs: '32rem',     
  },
  height: {
    xs: '35rem',      
  },    
  background: 'var(--color-background)',
  border: '2px solid #000',
  borderRadius: '2rem',
  borderColor: '#AC6633',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',   
};

export const modalCloseIcon: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '1rem',     
  },
  right: {
    xs: '1rem',     
  },   
  width: '2rem',
  height: '2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--color-background-icon)',
  cursor: 'pointer',
};

export const attachmentsStyle: SxProps = {
  width: {
    xs: '29rem',
  },
  height: {
    xs: '28rem',
  },
  borderStyle: 'solid',
  borderRadius: '1rem',
  borderColor: '#AC6633',
  marginTop: '1rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const attachmentUrlStyle: SxProps = {  
  fontSize: {
    xs: '1.7rem',    
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  marginTop: '0.3rem',
  marginBottom: '0.3rem',
};  

export const progressBarBoxStyle: SxProps = {
  width: {
    xs: '100%',      
  },
  marginBottom: '4rem',  
};
