import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '120rem',
    sm: '130rem',
    lg: '155rem',  
    xl: '85rem', 
  },  
  display: 'flex',
  flexDirection: {
    xs: 'column',    
  },
  justifyContent: {
    xs: 'flex-end',   
  },      
  alignItems: 'center', 
};

export const contentStyle: SxProps = {
  width: '100%',
  height: {
    xs: '107rem',
    sm: '110rem',
    lg: '130rem',
    xl: '50rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    xl: 'row',
  },
  justifyContent: {
    xs: 'space-evenly',
    xl: 'center',
  },      
  alignItems: 'center',  
};

export const cardStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '26.87rem',
    sm: '30rem',
    lg: '35.7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
  marginLeft: {
    lg: '3rem',
  },
  marginRight: {
    lg: '3rem',
  },  
};

export const titleBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',  
};

export const imageBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '16.87rem',
    sm: '20rem',
    lg: '25.7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '1.8rem',
    sm: '2rem',
    lg: '2.2rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-black)',
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.7rem',
    sm: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textDecorationStyle: 'solid',
  textDecorationColor: 'var(--color-text-white)',
  textDecorationLine: 'underline',
  cursor: 'pointer',
  marginBottom: '1rem',
};

export const freeBannerStyle: SxProps = {
  width: {
    xs: '35rem',
    sm: '50rem',
    lg: '75rem',
  },
  height: {
    xs: '7.77rem',
    sm: '11.11rem',
    lg: '16.66rem',
  },
  cursor: 'pointer',
  marginTop: {
    xs: '1rem',
    //sm: '1rem',
    //lg: '1rem',
  },
  marginBottom: {
    sm: '2rem',
    lg: '4rem',
  },  
};

export const freeLabelStyle: SxProps = {
  width: {
    xs: '28rem',
    sm: '40rem',
    lg: '60rem',
  },
  height: {
    xs: '1.86rem',
    sm: '2.66rem',
    lg: '3.99rem',
  },
  cursor: 'pointer',
  marginTop: {
    xs: '4rem',
    sm: '5rem',
    lg: '6rem',
  },
  /*marginBottom: {
    sm: '1rem',
    lg: '4rem',
  },*/  
};

export const imgStyle: SxProps ={
  width: '100%',
  height: '100%',
  cursor: 'pointer',
};

export const buttonStyle: SxProps = {
  width: {
    xs: '17rem',
    //sm: '25rem',
    //md: '18rem',
    //xl: '22rem',
  },
  height: {
    xs: '3rem',
    //sm: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text-black)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',
    //sm: '1.7rem',
    //md: '1.4rem',
    //xl: '1.6rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-black-hover)' },    
};

export const modalStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { 
    xs: '32rem',     
  },
  height: {
    xs: '35rem',      
  },    
  background: 'var(--color-background)',
  border: '2px solid #000',
  borderRadius: '2rem',
  borderColor: '#AC6633',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',   
};

export const modalCloseIcon: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '1rem',     
  },
  right: {
    xs: '1rem',     
  },   
  width: '2rem',
  height: '2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--color-background-icon)',
  cursor: 'pointer',
};

export const attachmentsStyle: SxProps = {
  width: {
    xs: '29rem',
  },
  height: {
    xs: '28rem',
  },
  borderStyle: 'solid',
  borderRadius: '1rem',
  borderColor: '#AC6633',
  marginTop: '1rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const attachmentUrlStyle: SxProps = {  
  fontSize: {
    xs: '1.7rem',    
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  marginTop: '0.3rem',
  marginBottom: '0.3rem',
};
