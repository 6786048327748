import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import BundleCourseLessonVideoPlayer from '../../components/PageComponents/BundleComponents/BundleCourseLessonVideoPlayer';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import IBundleCourseLesson from '../../data/interfaces/IBundleCourseLesson';

import {
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';

const BundleCourseLessonPage = () => {
  const [ bundleCourseLesson, setBundleCourseLesson ] = useState<IBundleCourseLesson|null>(null);
  const [ loader, setLoader ] = useState(true);
  const navigate = useNavigate();
  
  let { bundleUid, courseUid, courseLessonUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/bundles/'+bundleUid+'/courses/'+courseUid+'/lessons/'+courseLessonUid);
      if (response.data) {
        setBundleCourseLesson(response.data.bundleCourseLesson);
        setLoader(false);
      }      
    } catch (error: any) {
      if (error.message === 'bundle-not-acquired') {
        navigate('/super-curso/'+bundleUid);
      }
      console.log('unauthorized')
    }
  };

  useEffect(() => {
    console.log(bundleUid); 
    async function loadData() {
      try {
        const response = await api.get('/bundles/'+bundleUid+'/courses/'+courseUid+'/lessons/'+courseLessonUid);
        if (response.data) {
          setBundleCourseLesson(response.data.bundleCourseLesson);
          setLoader(false);
        }              
      } catch (error: any) {
        if (error.message === 'bundle-not-acquired') {
          navigate('/super-curso/'+bundleUid);
        }
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);      
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        bundleCourseLesson ?         
          <BundleCourseLessonVideoPlayer  bundleCourseLesson={bundleCourseLesson} bundleUid={bundleUid}/>                 
        :        
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Aula não encontrada!</Box>
          </Box>  
      }
      <Footer />
    </Box>
  );
}

export default BundleCourseLessonPage;
