import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Modal from '@mui/material/Modal';
import { MdClear } from "react-icons/md";

import IBundle from '../../../../data/interfaces/IBundle';

import {
  mainStyle,
  contentStyle,  
  titleBoxStyle,thumbnailBoxStyle,
  imgStyle,
  h1Style,  
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,  
  modalStyle,
  modalCloseIcon,
  attachmentsStyle,
  attachmentUrlStyle,  
} from './styles';

interface Props { 
  bundle: IBundle;  
}
type props = Props

const FOLHA_URL = 'https://folha.truenumbers.com.br';

const Bundle = ({ bundle }: props) => {  
  const [ open, setOpen ] = useState(false);
  const [ showMore, setShowMore ] = useState(false);

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };
  const handleShowMore = (value: boolean) => {
    setShowMore(value);
  }

  return (
    <Box sx={mainStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="support-material"
        aria-describedby="support-material"
        disableScrollLock={true}      
      > 
        <Box sx={modalStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box component='strong'>Material anexo:</Box>
          <Box sx={attachmentsStyle}>
            {
              bundle.attachments && bundle.attachments.length > 0 ?
                bundle.attachments.map((a: any) => {
                return (
                  <Box component='a' sx={attachmentUrlStyle} key={a.title} href={a.url} target='_blank' rel='noreferrer noopener'>{a.title}</Box> 
                )})               
              :
                <Box component='p'>Nada foi disponibilizado</Box>
            }                                     
          </Box>
        </Box>
      </Modal>               
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{bundle.title}</Box> 
        </Box>
        <Box sx={thumbnailBoxStyle}>
          <Box component='img' sx={imgStyle} src={bundle.thumbnail} alt='thumbnail'/>
        </Box>

        {
          showMore ?
            <>
              <Box component='p' sx={descriptionStyle}>{bundle.description}</Box>
              <Box component='p' sx={descriptionStyle} style={{
                width: '100%', 
                textAlign: 'start', 
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }} onClick={() => handleShowMore(false)}>Mostrar menos...</Box>
            </>            
          :
            <>
              <Box component='p' sx={descriptionStyle}>Descubra o poder revelador da Numerologia com o Super 
                Curso de Numerologia Pitagórica e Carmática, conduzido pela renomada numeróloga Ingrid Dalila Engel. 
                Este curso completo foi cuidadosamente projetado para quem deseja mergulhar profundamente nos 
                mistérios dos números e sua conexão com a evolução espiritual, oferecendo uma jornada de autoconhecimento 
                e transformação... </Box>
              <Box component='p' sx={descriptionStyle} style={{
                width: '100%', 
                textAlign: 'start', 
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }} onClick={() => handleShowMore(true)}>Mostrar mais...</Box>
            </>            
        }

        <Box sx={buttonBoxStyle}>
          <Grid container spacing={{ xs: '2rem', lg: '4rem' }} justifyContent='center' alignItems='center'>
            {
              bundle.courses &&
              bundle.courses.map((c) => {
                return (
                  <Grid  xs='auto' md='auto' lg='auto' key={c.uid}> 
                    <Link to={'/super-curso/'+bundle.uid+'/cursos/'+c.uid} style={{ textDecoration: 'none' }}> 
                      <Box component='button' sx={buttonStyle} type='button'>CURSO DE {c.title}</Box>
                    </Link>
                  </Grid>
                )
              })
            }
            {
              bundle.lessons &&
              bundle.lessons.map((l) => {
                return (
                  <Grid  xs='auto' md='auto' lg='auto' key={l.uid}> 
                    <Link to={'/super-curso/'+bundle.uid+'/mentorias/'+l.uid} style={{ textDecoration: 'none' }}> 
                      <Box component='button' sx={buttonStyle} type='button'>{l.title}</Box>
                    </Link>
                  </Grid>
                )
              })
            }                   
            <Grid  xs='auto' md='auto' lg='auto'> 
              <Box component='button' sx={buttonStyle} type='button' onClick={handleOpen}>MATERIAL DE APOIO</Box>
            </Grid>
          </Grid>               
        </Box>       
      </Box>
    </Box>
  );
};

export default Bundle;
