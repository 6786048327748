import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import BundleLessonVideoPlayer from '../../components/PageComponents/BundleComponents/BundleLessonVideoPlayer';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import IBundleLesson from '../../data/interfaces/IBundleLesson';

import { 
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';

const BundleLessonPage = () => {
  const [ bundleLesson, setBundleLesson ] = useState<IBundleLesson|null>(null);
  const [ loader, setLoader ] = useState(true);
  const navigate = useNavigate();
  
  let { bundleUid, lessonUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/bundles/'+bundleUid+'/lessons/'+lessonUid);
      if (response.data) {
        setBundleLesson(response.data.bundleLesson);
        setLoader(false);
      }      
    } catch (error: any) {
      if (error.message === 'bundle-not-acquired') {
        navigate('/super-curso/'+bundleUid);
      }
      console.log('unauthorized')
    }
  };

  useEffect(() => {

    async function loadData() {
      try {
        const response = await api.get('/bundles/'+bundleUid+'/lessons/'+lessonUid);        
        if (response.data) {          
          setBundleLesson(response.data.bundleLesson);
          setLoader(false);
        }            
      } catch (error: any) {        
        if (error.message === 'bundle-not-acquired') {
          navigate('/super-curso/'+bundleUid);
        }
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);              
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        bundleLesson ?
          <BundleLessonVideoPlayer bundleLesson={bundleLesson}/>                  
        :
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Mentoria não encontrada!</Box>
          </Box>      
      }
      <Footer />
    </Box>
  );
}

export default BundleLessonPage;
