import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  width: '100vw',
  height: '100vh',
  overflowX: 'hidden',
  backgroundImage: 'linear-gradient(to right, var(--color-background-bundle), var(--color-background-bundle-dark))', 
};

export const contentStyle: SxProps = {
  width: '100%',
  height: '40rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '2rem',
    sm: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: '#FFFFFF',  
};
