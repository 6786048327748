import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Modal from '@mui/material/Modal';
import { MdClear } from "react-icons/md";
import Loader from "react-js-loader";
import LinearProgress from '@mui/material/LinearProgress';

import CourseLesson from './CourseLesson';

import ICourse from '../../../data/interfaces/ICourse';
import ICourseLesson from '../../../data/interfaces/ICourseLesson';

import {
  mainStyle,
  contentStyle,  
  titleBoxStyle,thumbnailBoxStyle,
  imgStyle,
  h1Style,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  linkStyle,
  modalStyle,
  modalCloseIcon,
  attachmentsStyle,
  attachmentUrlStyle,
  progressBarBoxStyle,
} from './styles';

interface Props { 
  course: ICourse;  
}
type props = Props

const FOLHA_URL = 'https://folha.truenumbers.com.br';

const CourseLessons = ({ course }: props) => {  
  const [ courseLessons, setCourseLessons ] = useState<Array<any>|[]>([]);
  const [ open, setOpen ] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {  
    setCourseLessons(course.courseLessons);
    setProgress(Number(course.courseProgress));
  }, []);

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };  

  return (
    <Box sx={mainStyle}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="support-material"
        aria-describedby="support-material"
        disableScrollLock={true}      
      > 
        <Box sx={modalStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box component='strong'>Material anexo:</Box>
          <Box sx={attachmentsStyle}>
            {
              course.attachments && course.attachments.length > 0 ?
                course.attachments.map((a: any) => {
                return (
                  <Box component='a' sx={attachmentUrlStyle} key={a.title} href={a.url} target='_blank' rel='noreferrer noopener'>{a.title}</Box> 
                )})               
              :
                <Box component='p'>Nada foi disponibilizado</Box>
            }                                     
          </Box>
        </Box>
      </Modal>               
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>CURSO DE {course.title}</Box>
          {
            course.title === 'NUMEROLOGIA CABALÍSTICA' &&
              <Box component='h1' sx={h1Style}>(OS CICLOS DA VIDA)</Box>
          }   
        </Box>
        <Box sx={thumbnailBoxStyle}>
          <Box component='img' sx={imgStyle} src={course.thumbnail} alt='thumbnail'/>
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{course.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{course.description}</Box>
        <Box sx={buttonBoxStyle}>
          <Box component='a' sx={linkStyle} href={course.urlEbook} target='_blank' rel='noreferrer noopener'>
            <Box component='button' sx={buttonStyle} type='button'>MANUAL DE NUMEROLOGIA</Box>
          </Box>
          <Box component='a' sx={linkStyle} href={FOLHA_URL} target='_blank' rel='noreferrer noopener'>
            <Box component='button' sx={buttonStyle} type='button'>PROGRAMA DE CÁLCULO</Box>
          </Box>          
          <Box component='button' sx={buttonStyle} type='button' onClick={handleOpen}>MATERIAL DE APOIO</Box>
          <Link to={'/duvidas'} style={{ textDecoration: 'none' }}>  
            <Box component='button' sx={buttonStyle} type='button'>ENVIE SUAS DÚVIDAS!</Box>
          </Link>          
        </Box>
        <Box sx={progressBarBoxStyle}>
          <Box component='strong' sx={strongStyle}>{progress}% DE AULAS CONCLUIDAS</Box> 
          <LinearProgress variant="determinate" value={progress} />
        </Box>
       {
          courseLessons.length > 0 ?         
            courseLessons.map((c: ICourseLesson) => {             
              return (                
                <Grid  xs='auto' md='auto' lg='auto' key={c.uid}>                  
                  <CourseLesson courseLesson={c} courseUid={course.uid} key={c.uid}/>                               
                </Grid>
              )
            })          
          : 
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />    
       } 
      </Box>
    </Box>
  );
};

export default CourseLessons;
