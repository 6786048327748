import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import BundleCourseLessons from '../../components/PageComponents/BundleComponents/BundleCourseLessons';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import IBundleCourse from '../../data/interfaces/IBundleCourse';

import {
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';

const BundleCoursePage = () => {
  const [ bundleCourse, setBundleCourse ] = useState<IBundleCourse|null>(null);
  const [ loader, setLoader ] = useState(true);
  const navigate = useNavigate();
  
  let { bundleUid, courseUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/bundles/'+bundleUid+'/courses/'+courseUid);
      if (response.data) {
        setBundleCourse(response.data.bundleCourse);
        setLoader(false);
      }      
    } catch (error: any) {
      if (error.message === 'bundle-not-acquired') {
        navigate('/super-curso/'+bundleUid);
      }
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/bundles/'+bundleUid+'/courses/'+courseUid);
        if (response.data) {
          setBundleCourse(response.data.bundleCourse);
          setLoader(false);
        }           
      } catch (error: any) {
        if (error.message === 'bundle-not-acquired') {
          navigate('/super-curso/'+bundleUid);
        }
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);      
      }     
    }    
    loadData();    
  }, []);

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        bundleCourse?
          <BundleCourseLessons bundleCourse={bundleCourse}/>                  
        :        
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Curso não encontrado!</Box>
          </Box>  
      }
      <Footer />
    </Box>
  );
}

export default BundleCoursePage;
