import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';

import ICourse from '../../../../data/interfaces/ICourse';

import DateUtils from '../../../../utils/dateUtils';

import {
  mainStyle,
  titleBoxStyle,
  h1Style,
  contentStyle,
  imgStyle,
  buttonBoxStyle,
  buttonStyle,
  buttonOrderStyle, 
} from './styles';

interface Props { 
  course: ICourse
}
type props = Props

const Course = ({ course }: props) => {
  const dateUtils = DateUtils();

  return (
    <Box sx={mainStyle}>
      <Link to={'/cursos/'+course.uid} style={{ textDecoration: 'none' }}>  
        {
          !course.isReleased ?
            <Box sx={titleBoxStyle}>
              <Box component='h1' sx={h1Style}>{course.title}</Box>
              {
                course.title === 'NUMEROLOGIA CABALÍSTICA' &&
                  <Box component='h1' sx={h1Style}>(OS CICLOS DA VIDA)</Box>
              }        
            </Box>
          :
            <Box sx={titleBoxStyle}>
              <Box component='h1' sx={h1Style}>{course.title}</Box> 
            </Box>
        } 
        <Box sx={contentStyle}>               
          <Box component='img' sx={imgStyle} src={course.thumbnail} alt={course.title}/>                   
        </Box>
      </Link>    
      <Box sx={buttonBoxStyle}>
      <Link to={'/cursos/'+course.uid} style={{ textDecoration: 'none' }}>  
        {
          course.isAcquired ? 
            <Box component='button' sx={buttonStyle}>ACESSAR</Box>
          :            
            <Box component='button' sx={buttonOrderStyle}>SAIBA MAIS</Box>            
        }
        </Link>    
      </Box>     
    </Box>
  );
}

export default Course;
