import React from 'react';
import { 
  createBrowserRouter, 
  RouterProvider, 
} from 'react-router-dom';

import { useAuth }from './contexts/auth';

import App from './App';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import MainPage from './pages/MainPage';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import ProfileFormPage from './pages/ProfileFormPage';
import LoaderPage from './pages/LoaderPage';
import PrivacyAndUserTermsPage from './pages/PrivacyAndUserTerms';
import LessonsListPage from './pages/LessonsListPage';
import CoursesListPage from './pages/CoursesListPage';
import CommunityPage from './pages/CommunityPage';
import OrdersPage from './pages/OrdersPage';
import LessonPage from './pages/LessonPage';
import UserProfilePage from './pages/UserProfilePage';
import CoursePage from './pages/CoursePage';
import BonusLessonPage from './pages/BonusLessonPage';
import QuestionFormPage from './pages/QuestionFormPage';
import CourseLessonPage from './pages/CourseLessonPage';
import FaqPage from './pages/FaqPage';
import BundlePage from './pages/BundlePage';
import BundleLessonPage from './pages/BundleLessonPage';
import BundleCoursePage from './pages/BundleCoursePage';
import BundleCourseLessonPage from './pages/BundleCourseLessonPage';

const publicRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <LoaderPage />,    
    children: [
      {
        path: '/',
        element: <SignInPage />
      },
      {
        path: '/registrar-com-email',
        element: <SignUpPage />,
      },
      {
        path: '/recuperar-senha',
        element: <PasswordRecoveryPage />,
      },
      {
        path: '/termos-de-uso-e-privacidade',
        element: <PrivacyAndUserTermsPage />,
      },         
    ],
  },  
]);

const authenticatedRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <LoaderPage />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },      
      {
        path: '/cadastro',
        element: <ProfileFormPage />
      },
      {
        path: '/perfil',
        element: <UserProfilePage />
      },
      {
        path: '/mentorias',
        element: <LessonsListPage />
      },
      {
        path: '/mentorias/:lessonUid',
        element: <LessonPage />
      },
      {
        path: '/mentorias/bonus/:bonusLessonUid',
        element: <BonusLessonPage />
      },
      {
        path: '/cursos',
        element: <CoursesListPage />
      },
      {
        path: '/cursos/:courseUid',
        element: <CoursePage />
      },
      {
        path: '/cursos/:courseUid/aula/:courseLessonUid',
        element: <CourseLessonPage />
      },
      {
        path: '/super-curso/:bundleUid',
        element: <BundlePage />
      },
      {
        path: '/super-curso/:bundleUid/mentorias/:lessonUid',
        element: <BundleLessonPage />
      },
      {
        path: '/super-curso/:bundleUid/cursos/:courseUid',
        element: <BundleCoursePage />
      },
      {
        path: '/super-curso/:bundleUid/cursos/:courseUid/aula/:courseLessonUid',
        element: <BundleCourseLessonPage />
      },
      {
        path: '/comunidade',
        element: <CommunityPage />
      },
      {
        path: '/pedidos',
        element: <OrdersPage />
      },
      {
        path: '/termos-de-uso-e-privacidade',
        element: <PrivacyAndUserTermsPage />,
      },
      {
        path: '/duvidas',
        element: <QuestionFormPage />,
      },
      {
        path: '/perguntas-mais-frequentes',
        element: <FaqPage />,
      },       
    ],
  },  
]);

const Routes: React.FC = () => {
  const { signed } = useAuth();  
  return signed ? <RouterProvider router={authenticatedRoutes}/> : 
    <RouterProvider router={publicRoutes}/>;
}

export default Routes;
