import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import UserPanel from '../../components/PageComponents/UserPanel';
import BundleOrder from '../../components/PageComponents/BundleComponents/BundleOrder';
import Bundle from '../../components/PageComponents/BundleComponents/Bundle';
import Footer from '../../components/PageComponents/Footer';

import { useAuth } from '../../contexts/auth';

import api from '../../services/api';

import IBundle from '../../data/interfaces/IBundle';

import {
  mainStyle,
  contentStyle,
  h2Style,
} from './styles';


const BundlePage = () => {
  const [ bundle, setBundle ] = useState<IBundle|null>(null);
  const [ loader, setLoader ] = useState(true);
  
  let { bundleUid } = useParams();
  
  const { handleRefreshToken } = useAuth();  

  const reloadData = async () => {
    try {     
      const response = await api.get('/bundles/'+bundleUid);
      if (response.data) {
        setBundle(response.data.bundle);
        setLoader(false);
      }      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/bundles/'+bundleUid);
        if (response.data) {
          setBundle(response.data.bundle);
          setLoader(false);
        }          
      } catch (error: any) {
        if (error.message === 'unauthorized') {
          await handleRefreshToken();
          reloadData();     
        } 
        setLoader(false);      
      }     
    }    
    loadData();    
  }, []);  

  return (
    <Box sx={mainStyle}>     
      <UserPanel />      
      {
        bundle ?
          bundle.isAcquired ?
            <Bundle bundle={bundle}/>
          :
            <BundleOrder bundle={bundle}/>
        :        
        loader ?
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />
        :
          <Box sx={contentStyle}>
            <Box component='h2' sx={h2Style}>Super Curso não encontrado!</Box>
          </Box>
      }      
      <Footer />
    </Box>
  );
}

export default BundlePage;
