import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Modal from '@mui/material/Modal';
import { MdClear } from "react-icons/md";
import { IoMdOptions } from "react-icons/io";
import { IconContext } from "react-icons";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import ReactPlayer from 'react-player'
import { useAlert, positions } from 'react-alert'

import api from '../../../../services/api';

import IBundleLesson from '../../../../data/interfaces/IBundleLesson';

import { useLogHandler } from '../../../../contexts/logs';
import { useAuth } from '../../../../contexts/auth';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  h1MainStyle,
  videoBoxStyle,
  optionsBoxStyle,
  optionsStyle, 
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  linkStyle,
  modalStyle,
  modalCloseIcon,
  modalOptionsStyle,
  reactPlayer,
  attachmentsStyle,
  attachmentUrlStyle,
  optionsStrongStyle,
  optionsPStyle,
} from './styles';

interface Props {   
  bundleLesson: IBundleLesson;
}
type props = Props

const FOLHA_URL = 'https://folha.truenumbers.com.br';

const BundleLessonVideoPlayer = ({ bundleLesson }: props) => {    
  const [ open, setOpen ] = useState(false);
  const [ openOptions, setOpenOptions ] = useState(false);
  const [ videoUrl, setVideoUrl ] = useState(bundleLesson.video);

  const alert = useAlert() 
    
  const { handleRefreshToken } = useAuth();
  const { createLogLessonVideoView, createLogLessonVideoViewFull } = useLogHandler();

  useEffect(() => {
    createLogLessonVideoView(bundleLesson.uid);
  }, []);

  const handleOpen = () => { 
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };

  const handleOpenOptions = () => { 
    setOpenOptions(true);    
  };
  const handleCloseOptions = () => {    
    setOpenOptions(false);    
  };

  const handleChangeVideoQuality = (option: string) => {
    if (option === '1080p') setVideoUrl(bundleLesson.video);
    if (option === '720p') setVideoUrl(bundleLesson.video720p);
    if (option === '480p') setVideoUrl(bundleLesson.video480p);
    setOpenOptions(false);
  };

  const reloadData = async () => {
    try {     
      await api.put('/lessons/'+bundleLesson.uid+'/progress');
    } catch (error) {
      console.log('unauthorized')
    }
  };

  const handleFinishedVideo = async () => {
    createLogLessonVideoViewFull(bundleLesson.uid);
    try {     
      await api.put('/lessons/'+bundleLesson.uid+'/progress');
    } catch (error: any) {
      if (error.message === 'unauthorized') {
        await handleRefreshToken();
        reloadData();     
      } 
      console.log('unauthorized')
    }
  } 

  return (
    <Box sx={mainStyle}>       
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="support-material"
        aria-describedby="support-material"
        disableScrollLock={true}      
      > 
        <Box sx={modalStyle}>
          <Box sx={modalCloseIcon} onClick={handleClose}><MdClear/></Box>
          <Box component='strong'>Material anexo:</Box>
          <Box sx={attachmentsStyle}>
            {
              bundleLesson.attachments && bundleLesson.attachments.length > 0 ?
                bundleLesson.attachments.map((a: any) => {
                  return (
                    <Box component='a' sx={attachmentUrlStyle} key={a.title} href={a.url} target='_blank' rel='noreferrer noopener'>{a.title}</Box> 
                  )})               
              :
                <Box component='p'>Nada foi disponibilizado</Box>
            }                                     
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openOptions}
        onClose={handleCloseOptions}
        aria-labelledby="video-options"
        aria-describedby="video-options"
        disableScrollLock={true}      
      > 
        <Box sx={modalOptionsStyle}>
          <Box component='strong' sx={optionsStrongStyle}>Qualidade do Vídeo:</Box>
          <Box component='p' sx={optionsPStyle} onClick={() => handleChangeVideoQuality('1080p')}>Full HD (1080p)</Box>
          <Box component='p' sx={optionsPStyle} onClick={() => handleChangeVideoQuality('720p')}>HD (720p)</Box>
          <Box component='p' sx={optionsPStyle} onClick={() => handleChangeVideoQuality('480p')}>SD (480p)</Box>
        </Box>
      </Modal>

      <Box sx={contentStyle}>
        <Link to={'/super-curso/bb691177-48af-4636-aa26-0ad31739bc3a'} style={{ textDecorationColor: '#FFFFFF', width: '100%', cursor: 'pointer' }}>
          <Box component='h1' sx={h1MainStyle}>{bundleLesson.bundleTitle}</Box>
        </Link>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{bundleLesson.title}</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          <ReactPlayer
            style={reactPlayer}
            url={videoUrl}
            width='100%'
            height='100%'
            controls
            pip
            light={<img style={imgStyle} src={bundleLesson.thumbnail} alt='Thumbnail' />}
            onEnded={handleFinishedVideo}
            onError={() => {
              alert.show('Não disponível nesta qualidade!', 
                { type: 'error', 
                  timeout: 10000,
                  position: positions.MIDDLE,
                });
              setVideoUrl(bundleLesson.video)
            }}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />          
        </Box>
        <Box sx={optionsBoxStyle}>
          <IconContext.Provider value={{ color: 'var(--color-background-icon-dark)', size: '1em',className: "global-class-name" }}>
            <Tooltip 
              title="Ajustar opções de qualidade do vídeo"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            > 
              <Box component='h1' sx={optionsStyle} onClick={handleOpenOptions}>Opções</Box>
            </Tooltip>
            <IoMdOptions style={{ marginRight: '0.5rem', cursor: 'pointer'}} onClick={handleOpenOptions}/>
          </IconContext.Provider>
        </Box>

        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{bundleLesson.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{bundleLesson.description}</Box>
        {
          bundleLesson.value > 0 &&
          <Box sx={buttonBoxStyle}>
            <Box component='a' sx={linkStyle} href={bundleLesson.urlEbook} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={buttonStyle} type='button'>MANUAL DE NUMEROLOGIA</Box>
            </Box>
            <Box component='a' sx={linkStyle} href={FOLHA_URL} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={buttonStyle} type='button'>PROGRAMA DE CÁLCULO</Box>
            </Box>          
            <Box component='button' sx={buttonStyle} type='button' onClick={handleOpen}>MATERIAL DE APOIO</Box>
            <Link to={'/duvidas'} style={{ textDecoration: 'none' }}>  
              <Box component='button' sx={buttonStyle} type='button'>ENVIE SUAS DÚVIDAS!</Box>
            </Link>          
          </Box>
        }        
      </Box>
    </Box>
  );
};

export default BundleLessonVideoPlayer;
