import React, { useState } from 'react';
import  { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import { 
  toolbarStyle,
  menuStyle,
  menuTypoStyle,
  typoStyle,
  buttonStyle,
} from './styles';

const AppBarMenu: React.FC = () => {  
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);  

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };  
    
  return (
    <AppBar position="static" color='transparent'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={toolbarStyle}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <Tooltip 
                title="Menu"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
              >        
                <MenuIcon fontSize='large' sx={{ color: 'var(--color-background-icon)' }} />
              </Tooltip>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={menuStyle}
            >
              <MenuItem onClick={handleCloseNavMenu} sx={{ width: '15rem'}}>
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={menuTypoStyle}>INÍCIO</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/mentorias' style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={menuTypoStyle}>MENTORIAS</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/cursos' style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={menuTypoStyle}>CURSOS</Typography>
                </Link>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/super-curso/bb691177-48af-4636-aa26-0ad31739bc3a' style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={menuTypoStyle}>SUPER CURSO DE NUMEROLOGIA</Typography>
                </Link>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/comunidade' style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={menuTypoStyle}>COMUNIDADE</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to='/perguntas-mais-frequentes' style={{ textDecoration: 'none' }}>
                  <Typography textAlign="center" sx={menuTypoStyle}>FAQ</Typography>
                </Link>
              </MenuItem>              
            </Menu>
          </Box>          
          <Typography
            variant="h5"
            noWrap
            component="h5"            
            sx={typoStyle}
          >
            MENU
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Tooltip 
              title="Página Inicial"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            >  
              <Link to='/' style={{ textDecoration: 'none' }}>
                <Button onClick={handleCloseNavMenu} sx={buttonStyle}>
                  INÍCIO
                </Button>
              </Link>
            </Tooltip>

            <Tooltip 
              title="Página de Mentorias"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            >             
              <Link to='/mentorias' style={{ textDecoration: 'none' }}>
                <Button onClick={handleCloseNavMenu} sx={buttonStyle}>
                  MENTORIAS
                </Button>
              </Link>
            </Tooltip>

            <Tooltip 
              title="Página de Cursos para Iniciantes"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            >  
              <Link to='/cursos' style={{ textDecoration: 'none' }}>
                <Button onClick={handleCloseNavMenu} sx={buttonStyle}>
                  CURSOS
                </Button>
              </Link>
            </Tooltip>

            <Tooltip 
              title="Página do Super Curso de Numerologia"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            >  
              <Link to='/super-curso/bb691177-48af-4636-aa26-0ad31739bc3a' style={{ textDecoration: 'none' }}>
                <Button onClick={handleCloseNavMenu} sx={buttonStyle}>
                  SUPER CURSO DE NUMEROLOGIA
                </Button>
              </Link>
            </Tooltip>

            <Tooltip 
              title="Página da Comunidade no Whatsapp"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            >  
              <Link to='/comunidade' style={{ textDecoration: 'none' }}>
                <Button onClick={handleCloseNavMenu} sx={buttonStyle}>
                  COMUNIDADE
                </Button>
              </Link>
            </Tooltip>

            <Tooltip 
              title="Dúvidas mais frequentes"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.2rem' } } }}
            >  
              <Link to='/perguntas-mais-frequentes' style={{ textDecoration: 'none' }}>
                <Button onClick={handleCloseNavMenu} sx={buttonStyle}>
                  FAQ
                </Button>
              </Link>
            </Tooltip>
          </Box>         
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppBarMenu;
