import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Loader from "react-js-loader";
import Box from '@mui/system/Box';
import { SxProps, color } from '@mui/system';
import Modal from '@mui/material/Modal';

import { useAuth } from '../../../contexts/auth';

import api from '../../../services/api';

import IOrder from '../../../data/interfaces/IOrder';

import orderType from '../../../data/enums/orderType';

import Order from './Order';

import OrderUtils from '../../../utils/orderUtils';

import './styles.css';

const OrdersList = () => {
  const [ loader, setLoader ] = useState(true);
  const [ orders, setOrders ] = useState<Array<IOrder>|[]>([]);
  const [ open, setOpen ] = useState(false);
  const [ order, setOrder ] = useState<IOrder|any>({});
  const orderUtils = OrderUtils();

  const { handleRefreshToken } = useAuth();
  const navigate = useNavigate();

  const reloadData = async () => {
    try {      
      const response = await api.get('/orders/my-orders');
      if (response.data) {
        setOrders(response.data.orders);
      }
      setLoader(false);      
    } catch (error) {
      console.log('unauthorized')
    }
  };

  useEffect(() => {    
    async function loadData() {
      try {
        const response = await api.get('/orders/my-orders');        
        if (response.data) {
          setOrders(response.data.orders);          
        }
        setLoader(false);        
      } catch (error: any) {
        if (error.message === 'unauthorized') await handleRefreshToken();
        reloadData();                        
      }     
    }    
    loadData();     
  }, []);

  const handleReloadClick = async () => {
    try {
      setLoader(true);
      const response = await api.get('/orders/my-orders');        
      if (response.data) {
        setOrders(response.data.orders);          
      }
      setLoader(false);            
    } catch (error: any) {
      if (error.message === 'unauthorized') await handleRefreshToken();
      reloadData();                        
    } 
  };

  const handleOpen = (order: IOrder) => { 
    setOrder(order);
    setOpen(true);    
  };
  const handleClose = () => {    
    setOpen(false);    
  };

  const modelStyle: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '38rem', md:'45rem' },
    height: '40rem',    
    backgroundImage: 'linear-gradient(to right, #868686, #FFFFFF);',
    border: '2px solid #000',
    borderRadius: '2rem',
    borderColor: '#AC6633',
    boxShadow: 24,
    p: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const modelContentStyle: SxProps = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',   
  };
  const itemStyle: SxProps = {
    border: '1px solid #000',
    borderRadius: '1rem',
    borderColor: '#AC6633',
    bgcolor: '#EFEFEF',
    width: '100%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.8rem',
  };
  const itemTitleStyle: SxProps = {
    border: '1px solid #000',
    borderRadius: '1rem',
    borderColor: '#AC6633',
    bgcolor: '#EFEFEF',
    width: '100%',
    height: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.8rem',
    textAlign: 'center',
  };
  const buttonStyle: SxProps = {
    width: '12rem',
    height: '3rem',
    backgroundImage: 'linear-gradient(to right, var(--color-button-dark), var(--color-button-light))',
    color: 'var(--color-button-text-light)',
    border: '0',
    borderRadius: '0.4rem',  
    cursor: 'pointer',
    fontSize: '1.5rem',
    fontWeight: 600,
    fontFamily: 'barlow',    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    transition: '0.2s',
    marginTop: '1rem',
    '&:hover': { color: 'var(--color-button-text-light-hover)' },
  };

  return (
    <div className="orders-list">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle}>
          <Box sx={modelContentStyle}>
            <p>Autorização</p>
            {
              order.chargeId ?
              <Box component='strong' sx={itemStyle}>{order.chargeId.split('CHAR_')}</Box> :
              <Box component='strong' sx={itemStyle}> - - - </Box>
            }
            <p>Status do Pedido</p>            
            <Box component='strong' sx={itemStyle}>{orderUtils.handleStatus(order.status)}</Box>
            <p>Forma de Pagamento</p>            
            {
              order.paymentMethod ?
              <Box component='strong' sx={itemStyle}>{orderUtils.handlePaymentMethod(order.paymentMethod)}</Box> :
              <Box component='strong' sx={itemStyle}> - - - </Box>
            }
            {
              order.orderType === orderType.LESSON ?
                <>
                  <p>Mentoria</p>
                  <Box component='strong' sx={itemTitleStyle}>
                    <Link to={'/mentorias/'+order.lessonUid} style={{ textDecorationColor: '#000000', color: '#000000' }}>
                      {order.lessonTitle}
                    </Link>
                  </Box>
                </>
              : order.orderType === orderType.COURSE ?
                <>
                  <p>Curso</p>
                  <Box component='strong' sx={itemTitleStyle}>
                    <Link to={'/cursos/'+order.courseUid} style={{ textDecorationColor: '#000000', color: '#000000' }}>
                      {order.courseTitle}
                    </Link>
                  </Box>
                </>
              : order.orderType === orderType.BUNDLE ?
                <>
                  <p>Pacote</p>
                  <Box component='strong' sx={itemTitleStyle}>
                    <Link to={'/super-curso/'+order.bundleUid} style={{ textDecorationColor: '#000000', color: '#000000' }}>
                      {order.bundleTitle}
                    </Link>
                  </Box>
                </>
              :
               <>
                  <p>Mentorias e Cursos</p>
                  <Box component='strong' sx={itemTitleStyle}>
                    <Link to={'/'} style={{ textDecorationColor: '#000000', color: '#000000' }}>
                      TODO O SITE
                    </Link>
                  </Box>
                </>
            }                      
            <p>Data e Hora</p>            
            <Box component='strong' sx={itemStyle}>{orderUtils.handleDateHourSeconds(order.updatedAt)}</Box>
            <Box component='button' sx={buttonStyle} onClick={handleClose}>Fechar</Box>
          </Box>
        </Box>
      </Modal>
      <div className="orders-list-content">
        {
          loader &&
          <div id="loader">
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={100} />
          </div> 
        } 
        <div className="orders-list-main">
          <h2>MEUS PEDIDOS</h2>                
          <div className="list-box">
            {
              !loader &&
              <div className='list'>
                {
                  orders.length > 0 ?
                  orders.map((o: IOrder) => {
                    return (
                      <div style={{ cursor: 'pointer' }} onClick={() => handleOpen(o)} key={o.uid}>
                        <Order order={o} key={o.uid} />
                      </div>
                    )
                  }) :
                  <strong>Não há pedidos!</strong>
                }
              </div>
            }            
            <div className="button-box">               
              <button onClick={() => navigate(-1)}>Voltar</button>              
              <button onClick={handleReloadClick}>Recarregar</button>             
            </div>       
          </div>           
        </div> 
      </div>
    </div>
  );
}

export default OrdersList;
