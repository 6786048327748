import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'

import api from '../../../../services/api';

import IBundleCourseLesson from '../../../../data/interfaces/IBundleCourseLesson';

import { useLogHandler } from '../../../../contexts/logs';
import { useAuth } from '../../../../contexts/auth';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  reactPlayer,
  h3Style,
  buttonBoxStyle,
  buttonStyle,
  invisibleButtonStyle,
  backStrongStyle,
  linkStyle,
} from './styles';

interface Props { 
  bundleCourseLesson: IBundleCourseLesson;
  bundleUid: string|any;
}
type props = Props

const BundleCourseLessonVideoPlayer = ({ bundleCourseLesson, bundleUid }: props) => {    
  const { createLogCourseLessonVideoView, createLogCourseLessonVideoViewFull } = useLogHandler();
  const { handleRefreshToken } = useAuth();  
  
  useEffect(() => {
    createLogCourseLessonVideoView(String(bundleCourseLesson.courseUid), bundleCourseLesson.uid);
  }, []);

  const reloadData = async () => {
    try {     
      await api.put('/courses/'+bundleCourseLesson.courseUid+'/lessons/'+bundleCourseLesson.uid+'/progress');
    } catch (error) {
      console.log('unauthorized')
    }
  };

  const handleFinishedVideo = async () => {
    createLogCourseLessonVideoViewFull(String(bundleCourseLesson.courseUid), bundleCourseLesson.uid);
    try {     
      await api.put('/courses/'+bundleCourseLesson.courseUid+'/lessons/'+bundleCourseLesson.uid+'/progress');
    } catch (error: any) {
      if (error.message === 'unauthorized') {
        await handleRefreshToken();
        reloadData();     
      } 
      console.log('unauthorized')
    }
  }

  return (
    <Box sx={mainStyle}>
      <Box component='h3' sx={h3Style}>CURSO DE {bundleCourseLesson.courseTitle}</Box>
      <Link to={'/super-curso/'+bundleUid+'/cursos/'+bundleCourseLesson.courseUid} style={{ textDecorationColor: '#FFFFFF' }}>
        <Box component='strong' sx={backStrongStyle}>Voltar ao Curso</Box>  
      </Link>                  
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{bundleCourseLesson.title}</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          <ReactPlayer
            style={reactPlayer}
            url={bundleCourseLesson.video}
            width='100%'
            height='100%'
            controls
            pip
            light={<img style={imgStyle} src={bundleCourseLesson.thumbnail} alt='Thumbnail' />}
            onEnded={handleFinishedVideo}           
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{bundleCourseLesson.workload}</Box> 
        </Box>                
      </Box>
      <Box sx={buttonBoxStyle}>
        {
          bundleCourseLesson.previousLessonUid ?
            <Link reloadDocument to={'/super-curso/'+bundleUid+'/cursos/'+bundleCourseLesson.courseUid+'/aula/'+bundleCourseLesson.previousLessonUid} style={{ textDecoration: 'none' }}>
              <Box component='button' sx={buttonStyle}>ANTERIOR</Box>
            </Link>
          :
            <Box component='button' sx={invisibleButtonStyle}></Box>       
        }
        {
          bundleCourseLesson.nextLessonUid ?
            <Link reloadDocument to={'/super-curso/'+bundleUid+'/cursos/'+bundleCourseLesson.courseUid+'/aula/'+bundleCourseLesson.nextLessonUid} style={{ textDecoration: 'none' }}>
              <Box component='button' sx={buttonStyle}>PRÓXIMA</Box>
            </Link>
          :
            <Box component='button' sx={invisibleButtonStyle}></Box>     
        }                 
      </Box>
    </Box>
  );
};

export default BundleCourseLessonVideoPlayer;
